import styles from "./IPhone1415Pro1.module.css";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useEffect, useState } from "react";
import { useBalance, useAccount, useContractRead, useContractWrite, useWaitForTransaction, RpcError } from "wagmi";
import { contracts } from "../utils/Contracts";
import Presale from '../abis/Presale.json'
import { ethers, parseEther, formatEther } from 'ethers';

function mFormat(num) {
  return Math.abs(num) > 999999 ? Math.sign(num)*((Math.abs(num)/1000000).toFixed(1)) + 'm' : Math.sign(num)*Math.abs(num)
}
const IPhone1415Pro1 = () => {
  const { address } = useAccount();
  const { data } = useBalance({address: address});
  const [nftAmount, setNftAmount] = useState(0);
  const [mintLoading, setMintLoading] = useState(false);
  const [referrAddress, setReferrAddress] = useState(address);
  const [totalReferred, setTotalReferred] = useState(0);
  const [totalEarned, setTotalEarned] = useState(0);
  const [claimAmount, setClaimAmount] = useState(0);
  const presaleRate = 1428571;
  const [txHash, setTxHash] = useState('');
  const mintCost = 0.1;
  const [copied, setCopied] = useState(false);
  const [copyMsg, setCopyMsg] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const hanldeCopy = async () => {
    var cpText = `${window.location.origin.toString()}?referAddress=${address}`;
    try {
        await navigator.clipboard.writeText(cpText);
        setCopied(true)
        setCopyMsg('Copied')
      } catch (e) {
        setCopied(true);
        setCopyMsg("Could not copy!")
        throw e;
      } finally {
        setTimeout(() => {
            setCopied(false);
        }, 2000);
      }
  }

  const setParams = () => {
    const query = new URLSearchParams(window.location.search);
    var rAddress = query.get('referAddress') && query.get('referAddress') != '' && query.get('referAddress') != undefined ? query.get('referAddress'):address;
    console.log('url', window.location.href)
    console.log('query', query)
    console.log('refer', rAddress)
    setReferrAddress(rAddress);
  }

  const presaleContractConfig = {
    address: contracts.presale,
    abi: Presale.abi
  };

  const {data: totalReferredAmount, refetch: rReferred} = useContractRead({
    ...presaleContractConfig,
    functionName:'totalReffered',
    args:[address]
  })

  const {data: totalEarnedAmount, refetch: rEarned} = useContractRead({
    ...presaleContractConfig,
    functionName:'referEarned',
    args:[address]
  })

  const {data: totalAmountToClaim, refetch: rClaimable} = useContractRead({
    ...presaleContractConfig,
    functionName:'amountClaimable',
    args:[address]
  })

  const {data: totalContributedBalance, refetch: rContributedBalance} = useContractRead({
    ...presaleContractConfig,
    functionName:'balances',
    args:[address]
  })

  

  const {data: hardCapAmount, refetch: rHardCap} = useContractRead({
    ...presaleContractConfig,
    functionName:'hardCap'
  })

  const {data: enableClaimed, refetch: rEnableClaim} = useContractRead({
    ...presaleContractConfig,
    functionName:'enableClaim'
  })

  const {data: isPresaleEnded, refetch: rIsPresaleEnded} = useContractRead({
    ...presaleContractConfig,
    functionName:'isPresaleEnded'
  })

  const {data: totalRaised, refetch: rTotalRaised} = useContractRead({
    ...presaleContractConfig,
    functionName:'totalRaised'
  })

  const {data: hasClaimed, refetch: rHasClaimed} = useContractRead({
    ...presaleContractConfig,
    functionName:'claimed',
    args:[address]
  })

  const {refetch: waitTxFetch} = useWaitForTransaction({
    hash:txHash,
    onSuccess(receipt) {
      rTotalRaised?.();
      rHardCap?.();
      rContributedBalance?.();
      rClaimable?.();
      rReferred?.();
      rEarned?.();
      rHasClaimed?.();
      setNftAmount(0);
      setMintLoading(false)
    }
  })

  const { writeAsync: contribute, error: contributeError } = useContractWrite({
    ...presaleContractConfig,
    functionName: 'contribute',
  });

  const { writeAsync: claim, error: claimError } = useContractWrite({
    ...presaleContractConfig,
    functionName: 'claim',
  });


  const onContributeClick = async () => {
    try {
      setMintLoading(true);
      await contribute({
        args: [referrAddress],
        value: parseEther((nftAmount).toString())
      }).then(tx => {
        console.log(tx.hash);
        setTxHash(tx.hash);
        waitTxFetch?.();
      })
    } catch (error) {
      if(error.toString().includes('hard cap reached')){
        setErrorMsg('Sorry! Hard Cap Reached!')
      } else if(error.toString().includes('not started yet')){
        setErrorMsg('Sorry! Presale is not started yet!')
      }else if(error.toString().includes('already finished')){
        setErrorMsg('Sorry! Presale is already finished!')
      } else if(error.toString().includes('bnb amount can not be zero')){
        setErrorMsg('Sorry! BNB Amount can not be 0!')
      } else if(error.toString().includes('Claiming is not enabled yet')){
        setErrorMsg('Sorry! Claiming is not enabled yet!')
      } else if(error.toString().includes('already claimed')){
        setErrorMsg('Sorry! You have already claimed the presale tokens!')
      } else {
        setErrorMsg('Sorry! Something went wrong, Try agian!')
      }
      setMintLoading(false);
      setShowError(true);
    } finally {
      setTimeout(() => {
        setErrorMsg('')
        setShowError(false);
    }, 2000);
    }
  }

  const onClaim = async () => {
    try {
      setMintLoading(true);
      await claim().then(tx => {
        console.log(tx.hash);
        setTxHash(tx.hash);
        waitTxFetch?.();
      })
    } catch (error) {
      if(error.toString().includes('Claiming is not enabled yet')){
          setErrorMsg('Sorry! Claiming is not enabled yet!');
      } else if(error.toString().includes('no contribution')){
        setErrorMsg('Sorry! No contribution remaining to claim')
      } else if(error.toString().includes('already claimed')){
        setErrorMsg('Sorry! You have already claimed')
      } else {
        setErrorMsg('Something went wrong! Pls try again!');
      }

      setMintLoading(false);
      setShowError(true);
    } finally {
      setTimeout(() => {
        setErrorMsg('');
        setShowError(false);
    }, 2000);
    }
  }

  const handleAdd = () => {setNftAmount(nftAmount+1)}
  const handleSubs = () => {
    if(nftAmount > 1) {
      setNftAmount(nftAmount -1)
    }
  }

  useEffect(() => {
    setParams()
  })

  useEffect(() => {
    const timeoutId = setInterval(() => {
      rTotalRaised?.();
      rIsPresaleEnded?.();
      rEnableClaim?.();
    }, 1000);      
    return () => clearInterval(timeoutId);
  })

  return (
    
    <div className={styles.iphone1415Pro1} style={{position:'relative',minHeight:'1000px'}}>
      
      <div className={styles.frameParent} style={{zIndex:9999}}>
        <div className={styles.gh342Parent}>
          {/* <b className={styles.heroMint} style={{fontSize:'26px', padding:'12px'}}>HARBOR DAO PRESALE</b> */}
          <img className={styles.gh342Icon} alt="" src="/hdao1.jpg" />
        </div>
        <img 
          alt="Theme Vid" 
          src="/hdao_choose_you.gif"
          style={{
            width:'350px',
            border:'solid 1px #161515',
            borderRadius:'18px',
            marginTop:'-42px'
          }}  
        />
        <ConnectButton showBalance={false}  />
        {/* <div className={styles.whitelistForPresaleTier1Wrapper}>
          <b className={styles.heroMint}>
            <ul className={styles.whitelistForPresaleTier1}>
              <li className={styles.whitelistForPresale}>
                Whitelist for presale (Tier1)
              </li>
            </ul>
          </b>
        </div> */}
        <b className={styles.b}>
          <span>{`${Number(formatEther(totalRaised?totalRaised:0)).toLocaleString()}`}</span>
          <span className={styles.span}> | {`${Number(formatEther(hardCapAmount?hardCapAmount:0))} BNB`}</span>
        </b>
        <div>
          <progress className={styles.progress} style={{zIndex:"999",position:'relative'}} max="100" value={Number(formatEther(totalRaised?totalRaised:0))/Number(formatEther(hardCapAmount?hardCapAmount:0))*100}>{Number(formatEther(totalRaised?totalRaised:0))/Number(formatEther(hardCapAmount?hardCapAmount:0))*100}%</progress>
        </div>

        

        <div className={styles.frameGroup}>
          {/* <div className={styles.frameWrapper}>
            <div className={styles.frameContainer}>
                <img style={{width:'100%', height:'100%'}} className={styles.icon} alt="" src="/512.jpg" />
            </div>
          </div> */}
          <div>
            <div className={styles.balanceParent}>
              <b className={styles.balance}>Balance</b>
              <b className={styles.bnb}>{Number(data?data.formatted:0).toFixed(3)} BNB</b>
            </div>

            {
              isPresaleEnded
              ?
              <>
                <div className={styles.inputParentDiv}>
                  <div className={styles.ownerDetails}>
                    <span className={styles.ownerBalance}>Total Earned</span>
                    <span className={styles.ownerValue}>{Number(formatEther(totalEarnedAmount?totalEarnedAmount:0))}</span>
                  </div>
                  <div className={styles.ownerDetails}>
                    <span className={styles.ownerBalance}>Your Contribution</span>
                    <span className={styles.ownerValue}>{Number(formatEther(totalContributedBalance?totalContributedBalance:0)) - Number(formatEther(totalEarnedAmount?totalEarnedAmount:0))}</span>
                  </div>
                  <div className={styles.ownerDetails}>
                    <span className={styles.ownerBalance}>Amount To Claim</span>
                    <span className={styles.ownerValue}>{Number(formatEther(totalAmountToClaim?totalAmountToClaim:0))}</span>
                  </div>
                  </div>
                  <div>
                    <button disabled={!enableClaimed || hasClaimed} 
                      style={{
                        background:(!enableClaimed || hasClaimed) ?"rgb(134 134 134)":'',
                        color:(!enableClaimed || hasClaimed) ?"#ccc":'',
                        zIndex:999,
                        position:'relative',
                        cursor:(!enableClaimed || hasClaimed) ?"not-allowed":'pointer',
                      }} 
                      onClick={onClaim} 
                      className={styles.contributeBtn}
                    >
                      {
                        hasClaimed
                        ?'Already Claimed'
                        :'Claim'
                      }
                    </button>
                  </div>
              </>
              :
              <>
                <div className={styles.inputParentDiv}>
                  {
                    referrAddress != '' && referrAddress != undefined && referrAddress != address
                    ?
                    <div>
                      <span style={{color:'#dfd8d8'}}>Refer Address: </span>
                      <input disabled value={referrAddress} style={{color:'#888282'}} type="text" className={styles.textInputField} placeholder="No referral"/>
                    </div>
                    :''
                  }
                  <div style={{zIndex:999, position:'relative'}}>
                    <span style={{color:'#dfd8d8'}}>BNB Amount: </span>
                    <input onChange={(e)=> setNftAmount(e.target.value)} value={nftAmount} type="number" min="0" className={styles.textInputField} placeholder="Enter amount BNB"/>
                    <span className={styles.amountCalculate}>{mFormat(nftAmount * presaleRate)} HDAO</span>
                  </div>
                </div>
                <div>
                  <button style={{zIndex:999, position:'relative'}} onClick={onContributeClick} className={styles.contributeBtn}>CONTRIBUTE</button>
                </div>
              </>
            }
            <div style={{position:'relative',marginTop:'5px'}}>
              {
                showError?
                <span className={styles.errorMsg}>{errorMsg}</span>
                :''
              }
            </div>
          </div>
          <div style={{borderTop:'solid 1px #767676', width:'100%'}}>
            {
              address && address !="" && address != undefined
              ?
              <div className={styles.ownerDetails}>
                <span className={styles.ownerBalance}>Refer Link</span>
                <span className={styles.ownerValue}>
                  <button style={{position:'relative',zIndex:999}} className={styles.clickToCopy} onClick={hanldeCopy}>
                    Click To Copy
                    {
                      copied?<span className={styles.copiedText}>{copyMsg}</span>:''
                    }
                  </button>
                </span>
              </div>
              :'' 
            }

            <div style={{zIndex:999,position:'relative'}}>
              <div className={styles.ownerDetails}>
                <span className={styles.ownerBalance}>Total Referred</span>
                <span className={styles.ownerValue}>{Number(totalReferredAmount?totalReferredAmount:0)}</span>
              </div>


              <div className={styles.ownerDetails}>
                <span className={styles.ownerBalance}>Total Earned</span>
                <span className={styles.ownerValue}>{Number(formatEther(totalEarnedAmount?totalEarnedAmount:0))}</span>
              </div>

              <div className={styles.ownerDetails}>
                <span className={styles.ownerBalance}>Your Contribution</span>
                <span className={styles.ownerValue}>{Number(formatEther(totalContributedBalance?totalContributedBalance:0)) - Number(formatEther(totalEarnedAmount?totalEarnedAmount:0))}</span>
              </div>

              <div className={styles.ownerDetails}>
                <span className={styles.ownerBalance}>Amount To Claim</span>
                <span className={styles.ownerValue}>{Number(formatEther(totalAmountToClaim?totalAmountToClaim:0))}</span>
              </div>
            </div>

          </div>
          <div 
            style={{
              borderTop:'solid 1px #767676',
              textAlign:'center', 
              paddingTop:'10px', 
              display:'block', 
              width:'100%',
              zIndex:999,
              position:'relative'
            }}
          >
            <div>
              <a href="https://t.me/harbordao" target="_blank">
                <img style={{width:'30px', marginLeft:'6px'}} alt="" title="Telegram" src="/telegram512.png"/>
              </a>
              <a href="https://twitter.com/harbordao_xyz" target="_blank" title="Twitter">
                <img style={{width:'30px', marginLeft:'6px'}} alt="" src="/twitter.png"/>
              </a>
              <a href="https://docs.harbordao.xyz" target="_blank" title="Docs">
                <img style={{width:'30px', marginLeft:'6px'}} alt="" src="/gitbook.png"/>
              </a>
            </div>
            
          </div>
          <div style={{textAlign:'center', width:'100%', display:'block',paddingTop:'26px'}}>
                  {
                    mintLoading && nftAmount 
                    ?'Loading...':''
                  }
          </div>
        </div>
      </div>
      {/* <img className={styles.logoManage} src='hdao.jpg'/> */}
    </div>
  );
};

export default IPhone1415Pro1;
